import React from 'react'
import Topnavigationbar from '../components/Topnavigationbar'
import img1 from '../images/News/img1.jpeg'
import img2 from '../images/News/img2.jpeg'
import img3 from '../images/News/img3.jpeg'
import img4 from '../images/News/img4.jpeg'
import img5 from '../images/News/img5.jpeg'
import img6 from '../images/News/img6.jpeg'
import img7 from '../images/News/img7.jpeg'
import img8 from '../images/News/img8.jpeg'
import img9 from '../images/News/img9.jpeg'
import img10 from '../images/News/img10.jpeg'
import img11 from '../images/News/img11.jpeg'
import img12 from '../images/News/img12.jpeg'
import img13 from '../images/News/img13.jpeg'
import img14 from '../images/News/img14.jpeg'
import img15 from '../images/News/img15.jpeg'
import img16 from '../images/News/img16.jpeg'
import img17 from '../images/News/img17.jpeg'
import img18 from '../images/News/img18.jpeg'
import img19 from '../images/News/img19.jpeg'
import img20 from '../images/News/img20.jpeg'
import Footer from '../components/Footer'

function News() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Topnavigationbar />
      
      <div className="container mx-auto px-4 py-8">
        <br></br><br></br><br></br>
        <h1 className="text-2xl font-semibold text-gray-800 mb-6 text-center">2024/10/16 - News Article</h1>
        <p className="text-lg text-gray-700 mb-8 text-center">
          "මේ දින චතුරංග අබේසිංහ සහ අරෝෂ අතපත්තු සහෝදරයා මෙම වෙළෙද සංකීර්ණයේ චාරිකාවක් පැමිණි අතර අප සංගමය වෙත පවසා සිටියේ මෙම ගොඩනැගිල්ල කඩා ඉවත් කිරීම නවතා දමන බවත් සියලු නඩත්තු කටයුතු හැකි ඉක්මනින් යතා තත්වයට පත් කරන බවටත් පොරොන්දු විය."
        </p>

        {/* Image Gallery */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Image 1 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img1} alt="News 1" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>
          
          {/* Image 2 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img2} alt="News 2" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>
          
          {/* Image 3 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img3} alt="News 3" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>
          
          {/* Image 4 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img4} alt="News 4" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>
          
          {/* Image 5 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img5} alt="News 5" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 6 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img6} alt="News 6" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 7 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img7} alt="News 7" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 8 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img8} alt="News 8" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 9 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img9} alt="News 9" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 10 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img10} alt="News 10" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 11 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img11} alt="News 11" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 12 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img12} alt="News 12" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 13 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img13} alt="News 13" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 14 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img14} alt="News 14" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 15 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img15} alt="News 15" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 16 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img16} alt="News 16" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 17 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img17} alt="News 17" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 18 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img18} alt="News 18" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 19 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img19} alt="News 19" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>

          {/* Image 20 */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img src={img20} alt="News 20" className="w-full h-64 object-cover rounded-md mb-4" />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
  
}

export default News